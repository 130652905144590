<script>
/**
 * Footer Component
 */
export default {}
</script>

<template>
<!-- Footer Start -->
<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                {{ new Date().getFullYear()}} &copy; <a href="" class="text-danger">RedCabs</a>
            </div>

        </div>
    </div>
</footer>
<!-- end Footer -->
</template>
