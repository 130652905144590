<template>
  <div>
    <!-- Begin page -->

    <Vertical>

      <PageHeader />

      <b-alert
          :variant="notification.type"
          class="mt-3"
          v-if="notification.message !== null"
          :show="10"
          dismissible
      >{{ notification.message }}
      </b-alert>

      <b-overlay :show="loader" rounded="sm">

        <template #overlay>
          <div>
            <b-spinner small type="grow" variant="danger"></b-spinner>
            <b-spinner type="grow" variant="danger"></b-spinner>
            <b-spinner small type="grow" variant="danger"></b-spinner>
            <span class="sr-only text-dark">Please wait...</span>
          </div>
        </template>

      <slot />

      </b-overlay>
    </Vertical>

  </div>
</template>


<script>

import Vertical from "./vertical";
import PageHeader from "@/components/Page-header"

/**
 * Main layout
 */
export default {
  components: {
    Vertical,
    PageHeader
  },
  computed: {
    loader(){
      return this.$store.getters.LOADER
    },
    notification() {
      const notification = this.$store.getters.notification;
      if (notification.message){
        console.log('notification has message called: ' , notification.message)
      }
      return notification;
    },
  },

  mounted(){
    // window.Echo.channel('channel').listen('Websocket',(e)=>{
    //   console.log(e)
    // })
  }

};
</script>
