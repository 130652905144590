export const menuItems = [
    {
        id: 1,
        label: "menuitems.navigation.text",
        permission: "allow",
        isTitle: true
    },
    {
        id: 2,
        label: "menuitems.dashboard.text",
        permission: "manage dashboard",
        icon: "ri-dashboard-line",
        // badge: {
        //     variant: "success",
        //     text: "menuitems.dashboard.badge"
        // },
        isMenuCollapsed: false,
        link: '/',
    },
    // {
    //     label: "menuitems.rentals.text",
    //     isTitle: true
    // },
    // {
    //     label: "menuitems.rentals.show-room",
    //     icon: "ri-car-line",
    //     link: "/rentals/show-room"
    // },
    // {
    //     label: "menuitems.rentals.add-car",
    //     icon: "ri-car-line",
    //     link: "/rentals/add-car"
    // },
    // {
    //     label: "menuitems.rentals.view-controls",
    //     icon: "ri-car-line",
    //     link: "/rentals/view-controls"
    // },
    // {
    //     label: "menuitems.rentals.add-controls",
    //     icon: "ri-car-line",
    //     link: "/rentals/add-controls"
    // },

    {
        label: "menuitems.drivers.text",
        icon: "ri-taxi-wifi-fill",
        permission: "manage drivers",
        isMenuCollapsed: false,
        subItems: [
            {
                label: "menuitems.enrolment.prospective-drivers",
                icon: "ri-file-user-line",
                permission: "manage drivers",
                isMenuCollapsed: false,
                link: '/prospective-drivers',
            },
            {
                label: "menuitems.drivers.list",
                icon: "ri-file-user-line",
                permission: "manage drivers",
                link: "/drivers/list"
            },
            {
                label: "menuitems.drivers.add",
                icon: "ri-file-user-line",
                permission: "manage drivers",
                link: "/drivers/add"
            }
        ]
    },

    {
        label: "menuitems.rentals.text",
        icon: "ri-car-line",
        permission: "manage rentals",
        isMenuCollapsed: false,
        subItems: [
            {
                label: "menuitems.rentals.show-room",
                icon: "ri-file-user-line",
                link: "/rentals/show-room",
                permission: "manage rentals",
            },
            {
                label: "menuitems.rentals.add-car",
                icon: "ri-file-user-line",
                link: "/rentals/add-car",
                permission: "manage rentals",
            },
            {
                label: "menuitems.rentals.view-controls",
                icon: "ri-file-user-line",
                link: "/rentals/view-controls",
                permission: "manage rentals",
            },
            {
                label: "menuitems.rentals.add-controls",
                icon: "ri-file-user-line",
                link: "/rentals/add-controls",
                permission: "manage rentals",
            },
            {
                label: "menuitems.rentals.config",
                icon: "ri-file-user-line",
                link: "/rentals/general-configurations",
                permission: "manage rentals",
            },
        ]
    },
    {
        label: "menuitems.invoices.text",
        icon: "ri-file-user-line",
        isMenuCollapsed: false,
        link: '/invoices/list',
        permission: "manage invoices",
    },
    {
        label: "menuitems.service-charges.text",
        icon: "ri-file-user-line",
        isMenuCollapsed: false,
        link: '/service-charges/charges',
        permission: "manage service charges",
    },
    {
        label: "menuitems.providers.text",
        icon: "ri-file-user-line",
        permission: "manage providers",
        isMenuCollapsed: false,
        subItems: [
            {
                label: "menuitems.providers.providers",
                icon: "ri-file-user-line",
                permission: "manage providers",
                link: "/providers/list"
            },
            {
                label: "menuitems.providers.upload-provider-data",
                icon: "ri-file-user-line",
                link: "/providers/upload-provider-data",
                permission: "manage providers",
            }
        ]
    },
    {
        label: "menuitems.receipts.text",
        icon: "ri-dashboard-line",
        link: "/receipts/uploaded-receipts",
        permission: "manage receipts",
        isMenuCollapsed: false,
        id: 4,
        badge: {
            variant: "success",
            text: "0"
        },
        subItems: [
            {
                label: "menuitems.receipts.text",
                icon: "ri-file-user-line",
                link: "/receipts/uploaded-receipts",
                permission: "manage receipts",
                id: 4,
                badge: {
                    variant: "success",
                    text: "0"
                },
            },
            {
                label: "menuitems.all_receipts.text",
                icon: "ri-file-user-line",
                link: "/receipts/all-receipts",
                permission: "manage receipts",
            },

            {
                label: "menuitems.receipt_support.text",
                // icon: "ri-file-user-line",
                link: "/receipt/support",
                permission: "manage receipts",
            },
        ]
    },

    {
        label: "menuitems.fAdmin.text",
        icon: "ri-file-paper-line",
        permission: "manage f-admins",
        isMenuCollapsed: false,
        subItems: [
            {
                label: "menuitems.fAdmin.configure-provider-fields",
                icon: "ri-file-user-line",
                link: "/f-admin/configure-provider-fields",
                permission: "manage f-admins",
            },
            {
                label: "menuitems.fAdmin.configure-receipt-fields",
                icon: "ri-file-user-line",
                permission: "manage f-admins",
                link: "/f-admin/configure-receipt-fields"
            },
            {
                label: "menuitems.fAdmin.configure-service-charge-fields",
                icon: "ri-file-user-line",
                permission: "manage f-admins",
                link: "/f-admin/configure-service-charge-fields"
            },
            {
                label: "menuitems.fAdmin.configure-rental-fields",
                icon: "ri-file-user-line",
                permission: "manage f-admins",
                link: "/f-admin/configure-rental-fields"
            },
            {
                label: "menuitems.fAdmin.financial-data",
                icon: "ri-file-user-line",
                permission: "manage f-admins",
                link: "/f-admin/financial-data",
                // isMenuCollapsed: false,
                // subItems: [
                //     {
                //         label: "menuitems.fAdmin.financial-data",
                //         icon: "ri-file-user-line",
                //         permission: "manage f-admins",
                //         link: "/f-admin/financial-data"
                //     },
                //     {
                //         label: "menuitems.fAdmin.search-data",
                //         icon: "ri-file-user-line",
                //         permission: "manage f-admins",
                //         link: "/f-admin/financial-data"
                //     }
                //
                // ]
            },
            {
                label: "menuitems.fAdmin.financial-sheet",
                icon: "ri-file-user-line",
                permission: "manage f-admins",
                link: "/f-admin/financial-sheet"
            },

            {
                label: "menuitems.fAdmin.upload-ext-2021-data",
                icon: "ri-file-user-line",
                permission: "manage f-admins",
                link: "/f-admin/external-2021-upload"
            },
            // {
            //     label: "menuitems.fAdmin.upload-ext-cost",
            //     icon: "ri-file-user-line",
            //     link: "/f-admin/upload-ext-costs"
            // },

        ]
    },
    {
        label: "menuitems.payments.text",
        icon: "ri-file-user-line",
        permission: "manage payouts",
        isMenuCollapsed: false,
        subItems: [
            {
                label: "menuitems.fAdmin.payout-initial-setup",
                icon: "ri-file-user-line",
                permission: "set previous arrears",
                link: "/f-admin/payout-initial-setup"
            },
            {
                label: "menuitems.fAdmin.make-payout",
                icon: "ri-file-user-line",
                permission: "manage make payouts",
                link: "/f-admin/make-payout"
            },
            {
                label: "menuitems.fAdmin.payout-history",
                icon: "ri-file-user-line",
                permission: "manage payout history",
                link: "/f-admin/payout-history"
            },
            {
                label: "menuitems.fAdmin.payout-arrears-overview",
                icon: "ri-file-user-line",
                permission: "arrears overview",
                link: "/f-admin/arrears-overview"
            },
            {
                label: "menuitems.fAdmin.payout-investigate-arrears",
                icon: "ri-file-user-line",
                permission: "arrears overview",
                link: "/f-admin/investigate-arrears"
            },
        ]
    },
    {
        label: "menuitems.contracts.text",
        icon: "ri-file-edit-line",
        isMenuCollapsed: false,
        permission: "manage contracts",
        subItems: [
            {
                label: "menuitems.contracts.premium-partner",
                icon: "ri-file-user-line",
                permission: "manage contracts",
                link: "/contracts/premium-partner"
            },
            {
                label: "menuitems.contracts.medium-partner",
                icon: "ri-file-user-line",
                permission: "manage contracts",
                link: "/contracts/medium-partner"
            },
            {
                label: "menuitems.contracts.light-partner",
                icon: "ri-file-user-line",
                permission: "manage contracts",
                link: "/contracts/light-partner"
            },
            {
                label: "menuitems.contracts.light-partner-plus",
                icon: "ri-file-user-line",
                permission: "manage contracts",
                link: "/contracts/light-partner-plus"
            },
            {
                label: "menuitems.contracts.house-rules",
                icon: "ri-file-user-line",
                permission: "manage contracts",
                link: "/contracts/house-rules"
            },
            {
                label: "menuitems.contracts.rental-contract",
                icon: "ri-file-user-line",
                permission: "manage contracts",
                link: "/contracts/rental-contract"
            },
        ]
    },
    {
        id: 3,
        badge: {
            variant: "success",
            text: "0"
        },
        label: "menuitems.support.text",
        icon: "ri-chat-3-line",
        permission: "manage support",
        isMenuCollapsed: false,
        subItems: [
            {
                label: "menuitems.support.chat",
                icon: "ri-file-user-line",
                link: "/support/chat",
                permission: "manage support",
            },
            {
                label: "menuitems.support.bulk-messages",
                icon: "ri-file-user-line",
                link: "/support/bulk-messages",
                permission: "manage support",
            },
            {
                label: "menuitems.support.bulk-messages-history",
                icon: "ri-file-user-line",
                link: "/support/bulk-messages-history",
                permission: "manage support",
            },

        ]
    },
    {
        label: "menuitems.admin.text",
        icon: "ri-file-user-line",
        permission: "manage admins",
        isMenuCollapsed: false,
        link: '/admin/manage-admins',
    },
    // {
    //     label: "menuitems.admin.text",
    //     icon: "ri-file-user-line",
    //     permission: "manage admins",
    //     isMenuCollapsed: false,
    //     subItems: [
    //         {
    //             label: "menuitems.admin.manage",
    //             icon: "ri-file-user-line",
    //             link: "/admin/manage-admins",
    //             permission: "manage admins",
    //         },
    //         // {
    //         //     label: "menuitems.admin.roles",
    //         //     icon: "ri-file-user-line",
    //         //     link: "/admin/roles",
    //         //     permission: "manage admins",
    //         // },
    //         // {
    //         //     label: "menuitems.admin.permissions",
    //         //     icon: "ri-file-user-line",
    //         //     link: "/admin/permissions",
    //         //     permission: "manage admins",
    //         // },
    //
    //     ]
    // },
    {
        label: "menuitems.unit4.text",
        icon: "ri-file-user-line",
        isMenuCollapsed: false,
        permission: "manage unit-4",
        subItems: [
            {
                label: "menuitems.unit4.config",
                icon: "ri-file-user-line",
                link: "/unit-4/configs",
                permission: "manage unit-4",
            },
            {
                label: "menuitems.unit4.verkoop",
                icon: "ri-file-user-line",
                link: "/unit-4/verkoop",
                permission: "manage unit-4",
            },
            {
                label: "menuitems.unit4.inkoop",
                icon: "ri-file-user-line",
                link: "/unit-4/inkoop",
                permission: "manage unit-4",
            },
            {
                label: "menuitems.unit4.memoriaal",
                icon: "ri-file-user-line",
                link: "/unit-4/memoriaal",
                permission: "manage unit-4",
            },
            {
                label: "menuitems.unit4.kasbank",
                icon: "ri-file-user-line",
                link: "/unit-4/kasbank",
                permission: "manage unit-4",
            },

            {
                label: "menuitems.unit4.monthly-closing",
                icon: "ri-file-user-line",
                permission: "manage f-admins",
                link: "/f-admin/monthly-closing"
            },

            {
                label: "menuitems.unit4.monthly-result",
                icon: "ri-file-user-line",
                permission: "manage f-admins",
                link: "/f-admin/monthly-result"
            },
        ]
    },
    {
        label: "menuitems.settings.text",
        icon: "ri-file-settings-line",
        isMenuCollapsed: false,
        permission: "manage settings",
        subItems: [
            {
                label: "menuitems.settings.account-settings",
                icon: "ri-file-user-line",
                link: "/settings/account-settings",
                permission: "manage settings",
            },
            {
                label: "menuitems.settings.faq",
                icon: "ri-file-user-line",
                link: "/settings/faq",
                permission: "manage faq",
            }
        ]
    },
    // {
    //     label: "menuitems.test.arrears",
    //     icon: "ri-file-user-line",
    //     link: "/test/arrears",
    //     permission: "manage test arrears",
    // },

    // {
    //     id: 8,
    //     label: "menuitems.ecommerce.text",
    //     icon: "ri-shopping-cart-2-line",
    //     badge: {
    //         variant: "danger",
    //         text: "menuitems.ecommerce.badge"
    //     },
    //     isMenuCollapsed: false,
    //     subItems: [
    //         {
    //             id: 9,
    //             label: "menuitems.ecommerce.list.products",
    //             link: "/ecommerce/products"
    //         },
    //         {
    //             id: 10,
    //             label: "menuitems.ecommerce.list.productsgrid",
    //             link: "/ecommerce/products-grid"
    //         },
    //         {
    //             id: 11,
    //             label: 'menuitems.ecommerce.list.productdetail',
    //             link: '/ecommerce/product-detail/1'
    //         },
    //         {
    //             id: 12,
    //             label: 'menuitems.ecommerce.list.createproduct',
    //             link: '/ecommerce/product-create'
    //         },
    //         {
    //             id: 13,
    //             label: "menuitems.ecommerce.list.customers",
    //             link: "/ecommerce/customers"
    //         },
    //         {
    //             id: 14,
    //             label: "menuitems.ecommerce.list.orders",
    //             link: "/ecommerce/orders"
    //         },
    //         {
    //             id: 15,
    //             label: "menuitems.ecommerce.list.orderdetail",
    //             link: "/ecommerce/order-detail"
    //         },
    //         {
    //             id: 16,
    //             label: "menuitems.ecommerce.list.sellers",
    //             link: "/ecommerce/sellers"
    //         },
    //         {
    //             id: 17,
    //             label: "menuitems.ecommerce.list.cart",
    //             link: "/ecommerce/cart"
    //         },
    //         {
    //             id: 18,
    //             label: "menuitems.ecommerce.list.checkout",
    //             link: "/ecommerce/checkout"
    //         }
    //     ]
    // },
    // {
    //     id: 19,
    //     label: 'menuitems.calendar.text',
    //     icon: 'ri-calendar-2-line',
    //     link: '/apps/calendar'
    // },

];

